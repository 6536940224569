/**
 * Entry point for reduced widget bundle used in china
 */

 import '@/widgets/contact';
import '@/widgets/download-terms';
import '@/widgets/scroll-indicator';
import '@/widgets/search';
import { services } from '@/bootstrap';
import { dom } from '@/widgets/login/config';

// Add event listener because login widget is not loaded
const logoutButton = document.getElementById(dom.menuLogoutId);
if (logoutButton) {
    logoutButton.addEventListener('click', async (event) => {
        event.preventDefault();
        await services('$store').dispatch('user/requestLogout');
    });
}