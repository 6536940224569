/**
 * UI
 *
 * Provides functions to manipulate DOM outside of vue context.
 * Should be converted to vue components in future.
 */

import { env, log } from '@/bootstrap';
import { dom } from '@/widgets/login/config';
import { services } from '@/bootstrap';


// Manipulates DOM elements to reflect login state
// This function is old and hacky, but does its job
export const updateLoginState = (session = null) => {
    if (env('isChina'))
        return;
    const loginButtonDesktopNode = document.getElementById(dom.loginButtonDesktopId);
    const loginButtonMobileNode = document.getElementById(dom.loginButtonMobileId);
    const myHartingloginButtonDesktopNode = document.getElementById(dom.myHartingloginButtonDesktopId);
    const myHartingloginButtonMobileNode = document.getElementById(dom.myHartingloginButtonMobileId);
    const accountNodes = document.getElementsByClassName(dom.accountIdClass);
    const accountMenuNode = document.getElementById(dom.accountMenuId);
    const myHartingButton = document.getElementById(dom.myHartingButton);
    const menuButton=document.getElementsByClassName(dom.clickbutton);

    if (session) {
        const { sapId, uid } = session;
        if (accountNodes && accountNodes.length) {
            for (const accountNode of accountNodes) {
                const userCard = accountNode.querySelector(`.${dom.userIdClass}`);
                if (userCard) {
                    const ids = [sapId, uid].filter(id => id && id !== 'DUMMY');
                    userCard.innerHTML = ids.join(' | ');
                    accountNode.classList.remove(dom.accountIdHiddenClass);
                }
            }
        }

        // Show account menu
        loginButtonDesktopNode && loginButtonDesktopNode.classList.add(dom.hideButtonClass);
        loginButtonMobileNode && loginButtonMobileNode.classList.add(dom.hideButtonClass);
        myHartingloginButtonDesktopNode && myHartingloginButtonDesktopNode.classList.remove(dom.hideButtonClass);
        myHartingloginButtonMobileNode && myHartingloginButtonMobileNode.classList.remove(dom.hideButtonClass);
        accountMenuNode && (accountMenuNode.style.display = 'flex');
        if (!myHartingButton) return;
        myHartingButton.addEventListener('click', () => {
            window.location.href = routes('URL_HARTING_RAW')+'myharting';
        });
        if (menuButton){
            for (var i = 0, max = menuButton.length; i < max; i++) {
                menuButton[i].removeEventListener('click', clickMenu);
                menuButton[i].addEventListener('click', clickMenu);
            }
        }


    } else {
        // Show login button
        loginButtonDesktopNode && loginButtonDesktopNode.classList.remove(dom.hideButtonClass);
        loginButtonMobileNode && loginButtonMobileNode.classList.remove(dom.hideButtonClass);
        myHartingloginButtonDesktopNode && myHartingloginButtonDesktopNode.classList.add(dom.hideButtonClass);
        myHartingloginButtonMobileNode && myHartingloginButtonMobileNode.classList.add(dom.hideButtonClass);
        accountMenuNode && (accountMenuNode.style.display = 'none');
    }
};

// Updates displayed time until logout
// This function is old and hacky, but does its job
export const updateTopBarStatus = (text, iconClass) => {
    if (env('isChina'))
        return;
    const containerNode = document.getElementById(dom.topBarGuiContainerId);
    const iconNode = document.getElementById(dom.topBarGuiIconId);
    const textNode = document.getElementById(dom.topBarGuiTextId);
    if (!containerNode || !iconNode || !textNode) {
        const nodeData = JSON.stringify({ containerNode, iconNode, textNode });
        log(`UpdateTopBarStatus: Nodes not found: ${nodeData}`, 'error', 'ui-store');
        return;
    }

    iconNode.className = `fa ${iconClass}`;
    textNode.innerHTML = `(${text})`;
    if (containerNode.classList.contains(dom.hideTopBarGuiClass)) {
        containerNode.classList.remove(dom.hideTopBarGuiClass);
    }
};

export const updateMenu = (text) => {
    if (env('isChina')) return;
    const dropdownMenuNode=document.getElementById(dom.dropdownMenuId);
    if (dropdownMenuNode!=null){
        dropdownMenuNode.innerHTML=text;
    }
};
var clickMenu =function (event){
    var el= event.target || event.srcElement;
    if ( el.classList.contains('header-user-card__dropdown-button--active')){
        event.preventDefault();
        services('$store').dispatch('ui/requestMenu');
    }

}