import Vue from 'vue';
import to from 'await-to-js';
import { env, log, routes } from '@/bootstrap';
import { tryValue, timestampSeconds } from '@/libs/util';
import { createRequestAdapter } from '@/libs/adapter';
import {
    registries,
    waitForEvents,
    COMMON_REGISTRY,
    UNIQUE_REGISTRY,
    LANGUAGE_UPDATED,
    SESSION_CREATED,
    UI_STORE_CREATED,
    USER_STORE_CREATED,
    COOKIE_STORE_CREATED,
    NATIVE_CLICK,
    SESSION_UPDATED,
} from '@/events';


export default {
    namespaced: true,
    state: {
        httpLogin: null,
        httpHybris: null,
        httpDrupal:null,
        userId: null,
        sapId: null,
        loginEnabled: true,
        isLoggedIn: false,
        sessionData: null, // For debugging purposes, do not access directly
        cartAmount: 0,
        watchlistAmount: 0,
        lastSessionRefresh: timestampSeconds(),
        refreshTimeout: 10,
        watchlists: [],
    },
    mutations: {
        SET_STATE(state, { key, value }) {
            Vue.set(state, key, value);
        },
    },
    actions: {
        async init({ state, commit, dispatch, getters }) {
            commit('SET_STATE', {
                key: 'httpLogin',
                value: createRequestAdapter({
                    url: routes('URL_IP_BASE'),
                })
            });
            commit('SET_STATE', {
                key: 'httpHybris',
                value: createRequestAdapter({
                    url: env('DOMAIN_SHOP'),
                    auth: {
                        username: env('HTTP_AUTH_SHOP_USERNAME'),
                        password: env('HTTP_AUTH_SHOP_PASSWORD'),
                    },
                })
            });
            commit('SET_STATE', {
                key: 'httpDrupal',
                value: createRequestAdapter({
                    url: routes('URL_DRUPAL_BASE'),
                    auth: {
                        username: env('HTTP_AUTH_WEBSITE_USERNAME'),
                        password: env('HTTP_AUTH_WEBSITE_PASSWORD'),
                    },
                })
            });
            // Wait until ui and cookie store is created
            await waitForEvents([
                [UNIQUE_REGISTRY, UI_STORE_CREATED, { timeout: 1000 }],
                [UNIQUE_REGISTRY, COOKIE_STORE_CREATED, { timeout: 1000 }],
            ]);
            // Request user session
            const [error, session] = await to(dispatch('requestSession'));
            if (error) {
                log(error, 'error', 'hybris-session');
            }
            registries(COMMON_REGISTRY).on(NATIVE_CLICK, () => {
                // Prevent spamming of API calls
                const now = timestampSeconds();
                if (state.lastSessionRefresh && now - state.lastSessionRefresh < state.refreshTimeout) return;
                // Update session
                commit('SET_STATE', { key: 'lastSessionRefresh', value: now });
                dispatch('requestSession');
            });
            registries(UNIQUE_REGISTRY).emit(SESSION_CREATED, (error ? null : session));
            registries(UNIQUE_REGISTRY).emit(USER_STORE_CREATED, getters);
        },
        async updateUserId({ commit }, id) {
            commit('SET_STATE', { key: 'userId', value: id });
            window.Qualtrics=window.Qualtrics||[];
            window.Qualtrics.userId=id;
        },
        async updateSapId({ commit }, id) {
            commit('SET_STATE', { key: 'sapId', value: id });
            window.Qualtrics=window.Qualtrics||[];
            window.Qualtrics.contactId=id;
        },
        async updateCartAmount({ commit }, amount) {
            commit('SET_STATE', { key: 'cartAmount', value: amount });
        },
        async updateWatchlistAmount({ commit }, amount) {
            commit('SET_STATE', { key: 'watchlistAmount', value: amount });
        },
        async updateIsLoggedIn({ commit }, isLoggedIn) {
            commit('SET_STATE', { key: 'isLoggedIn', value: isLoggedIn });
        },
        async updateSessionData({ dispatch, commit, state }, session) {
            commit('SET_STATE', { key: 'sessionData', value: session });
        },
        async requestSession({ state, rootGetters, dispatch }) {
            // Check if cookie exists
            const { 'cookies/authToken': token } = rootGetters;
            if (!token || typeof token !== 'string') {
                dispatch('updateSession', null);
                return null;
            }

            // Get hybris session for auth token
            const sessionRequest = state.httpLogin.post({
                path: '/message',
                data: {
                    type: 'session',
                    data: { token }
                },
            });
            const [sessionError, sessionResponse] = await to(sessionRequest);
            if (sessionError || !tryValue(sessionResponse, ['data', 'data', 'sapId'])) {
                const err = tryValue(sessionResponse, ['data', 'error'], 'Invalid session data');
                log(err, 'error', 'hybris-session');
                dispatch('updateSession', null);
                return null;
            }

            // Update client session
            const session = sessionResponse.data.data;
            //localStorage.setItem('validUntil', session.validUntil);
            dispatch('cookies/setCookie', { name: 'validUntil', value: session.validUntil }, { root: true });

            dispatch('updateSession', session);
            return session;
        },
        async updateSession({ dispatch, rootGetters }, session) {
            if (session && typeof session === 'object') {
                // Valid session
                const { uid, sapId, basketItemAmount, language } = session;
                dispatch('updateUserId', uid);
                dispatch('updateSapId', sapId);
                dispatch('updateIsLoggedIn', true);
                dispatch('updateSessionData', session);
                //dispatch('updateCartAmount', basketItemAmount);

                registries(COMMON_REGISTRY).emit(LANGUAGE_UPDATED, { langCode: language });              
            } else {
                // Invalid session
                dispatch('updateUserId', null);
                dispatch('updateSapId', null);
                dispatch('updateIsLoggedIn', false);
                dispatch('updateSessionData', null);
                dispatch('updateCartAmount', 0);
                dispatch('updateWatchlistAmount', 0);
                dispatch('cookies/removeCookie', rootGetters['cookies/authTokenName'], { root: true });
            }
            dispatch('ui/updateSession', session, { root: true });
            registries(COMMON_REGISTRY).emit(SESSION_UPDATED, session);
        },
        async requestLogin({ commit, dispatch, rootGetters, state }, { username, password }) {
            // Request login and expect auth token
            const loginRequest = state.httpLogin.post({
                path: '/login',
                data: {
                    login: username,
                    password: password,
                    language: env('languageShort'),
                },
            });
            const [loginError, loginResponse] = await to(loginRequest);
            if (loginError || !tryValue(loginResponse, ['data', 'data', 'token'])) {
                throw loginError;
            }

            // Set token cookie
            const cookieName = rootGetters['cookies/authTokenName'];
            const { data: { data: { token, user } } } = loginResponse;
            dispatch('cookies/setCookie', { name: cookieName, value: token }, { root: true });

            // Establish hybris session
            const hybrisRequest = state.httpHybris.get();
            const [hybrisError, hybrisResponse] = await to(hybrisRequest);
            
            if (hybrisError) {
                log(hybrisError, 'error', 'hybris-session');
            }
            dispatch('updateSession', user);
            return token;
        },
        async requestLoginState({ commit, state }) {
            await registries(UNIQUE_REGISTRY).wait(USER_STORE_CREATED, { timeout: 1000 });
            const loginStateRequest = state.httpLogin.get({ path: '/login-state' });
            const [loginStateError, loginStateResponse] = await to(loginStateRequest);
            if (loginStateError) {
                throw loginStateError;
            }

            const loginEnabled = tryValue(loginStateResponse, ['data', 'loginEnabled']);
            if (typeof loginEnabled === 'boolean') {
                commit('SET_STATE', {
                    key: 'loginEnabled',
                    value: loginEnabled,
                });
            }

            return loginEnabled;
        },
        async requestLogout({ state, commit, dispatch, rootGetters }, { withRedirect = true, force = false }) {
            const { 
                'user/isLoggedIn': isLoggedIn, 
                'cookies/authToken': authToken,
                'cookies/authTokenName': cookieName,
            } = rootGetters;
            if (!isLoggedIn && !force) return false;
            dispatch('cookies/setCookie', { name: 'validUntil', value: 0 }, { root: true });
            const logoutRequest = state.httpLogin.post({
                route: routes('VUE_APP_URL_IP_LOGOUT'),
                data: { token: authToken }
            });
            const logoutDrupal=state.httpDrupal.get({
                path: '/user/logout',
            })
            const [err, res] = await to(logoutRequest);
            if (err) {
                log(`${err}`, 'error', 'user');
                return false;
            }
            
            const cookie = {
                name: cookieName,
                args: { domain: env('COOKIE_DOMAIN') }
            };
            await dispatch('cookies/removeCookie', cookie, { root: true });
            await dispatch('updateIsLoggedIn', false);
            dispatch('updateCartAmount', 0);
           
            if (withRedirect) {
                window.location.href = routes('URL_HARTING_LOGOUT');
            }
            return true;
        },
    },
    getters: {
        userId: state => state.userId,
        isLoggedIn: state => state.isLoggedIn,
        loginEnabled: state => state.loginEnabled,
        sessionData: state => state.sessionData,
        cartAmount: state => state.cartAmount,
        watchlistAmount: state => state.watchlistAmount,
        userState: state => ({
            id: state.userId,
            sapId: state.sapId,
            isLoggedIn: state.isLoggedIn,
        }),
    }
};